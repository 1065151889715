import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { thecontext } from '../../Context/index';
import Ideas from './Ideas/Ideas';
import Contactaction from '../Contactaction/Contactaction';
import PrintsNotOver from '../PrintsNotOver/PrintsNotOver';

import { Event } from '../Tracking/Tracking';
import './Home.scss';

import Poster from '../../Images/hero_poster.jpg';

import vidMp3 from '../../Video/PN_Vid_Final.mp4';
import vidWebm from '../../Video/PN_Vid_Final.webm';

import { ReactComponent as SoundIcon } from '../../Images/SVG/sound-icon.svg';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { videoMute: false };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('home');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 10);

    const vid = document.getElementById('myVideo');
    vid.controls = false;
    vid.playsinline = true;
    vid.muted = true;
    vid.setAttribute("muted", ""); // leave no stones unturned :)
    vid.autoplay = true;
    setTimeout(() => vid.play(), 10);
  }

  componentWillUnmount = () => {
    const vid = document.getElementById('myVideo');
    const { videoMute } = this.state;
    this.setState({ videoMute: true });
    if (!vid.muted) {
      vid.muted = videoMute;
    }
    vid.pause();
    vid.remove();
  }

  muteVideo= () => {
    const getMute = document.getElementById('mute-svg');
    const vid = document.getElementById('myVideo');
    const { videoMute } = this.state;
    if (!videoMute) {
      vid.muted = videoMute;
      this.setState({ videoMute: true });
      getMute.style.marginLeft = '12px';
      Event('NAVIGATION_HERO_VIDEO_MUTE', 'Hero Video UnMuted', String(window.location.pathname));
    } else {
      vid.muted = videoMute;
      this.setState({ videoMute: false });
      getMute.style.marginLeft = '-24px';
      Event('NAVIGATION_HERO_VIDEO_MUTE', 'Hero Video Muted', String(window.location.pathname));
    }
  }

  handleKeyPress = () => {

  }

  render = () => {
    const { videoMute } = this.state;
    return (
      <div>
        <div id="home" className="fadeOut">
          <Helmet>
            <meta charSet="utf-8" />
            <title>We Are Productivity Network / Home</title>
            <meta name="description" content={thecontext.metaDescription} />
          </Helmet>

          <div>
            <PrintsNotOver></PrintsNotOver>
          </div>

        <div>
          <Ideas colorbg="#000064" colorlink="white" colorcopy="white" />
        </div>
        
        <div id="video-header">
            <div className="vimeo-wrapper">
              <video id="myVideo"  loop muted autoPlay playsInline poster={Poster}>
                <source src={vidMp3} type="video/mp4" />
                <source src={vidWebm} type="video/webm" />
              </video>

              <div id="mute-btn" role="button" tabIndex="0" onKeyPress={this.handleKeyPress} onClick={this.muteVideo}>
                <SoundIcon id="mute-svg" />
              </div>

            </div>
          </div>
        </div>

        
      <div>
        <Contactaction colorbg="#000064" colorcopy="#ffffff" colorlink="#f7941d" />
      </div>

      </div>
    );
  }
}

export default Home;
