import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { PropTypes } from 'prop-types';
import { Event } from '../../Tracking/Tracking';
import './Founders.scss';
import Erika from '../../../Images/Erika.jpg';
import Mike from '../../../Images/Mike.jpg';

class Founders extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('founders');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 1000);
    this.setState({ });
  }

  update = () => {
    this.setState({ });
  }

  trackFounders = () => {
    const { inViewport } = this.props;
    if (inViewport) {
      Event('FOUNDERS', 'Founders - enter view', String(window.location.pathname));
    } if (!inViewport) {
      Event('FOUNDERS', 'Founders - leave view', String(window.location.pathname));
    }
    return {};
  }

  render() {
    return (
      <div id="founders" className="fadeOut" style={this.trackFounders()}>
        <h1>Co-Founders</h1>
        <div id="founder-container-wide">
          <div id="founder-container">
            <div className="founder-child">
              <img src={Erika} width="300px" alt="ERIKA PFLEDERE - Founder" title="ERIKA PFLEDERER - Founder" />
              <h2>ERIKA PFLEDERER</h2>
            </div>

            <div id="founder-container-wide" className="bottom-margin only-mobile">
              <p className="founder-bio">Erika grew up in central Illinois working at her Dad’s print shop before ultimately heading to Chicago to work in advertising. She has years of experience in nearly every aspect of production, from print to digital and motion. As an executive manager, she modernized and integrated production and studio teams of all sizes, turned production specialists into skilled, multi-faceted generalists, and grew studio revenue into the multiple millions. When she’s not reading the latest issue of <i>Fast Company</i>, you will likely find Erika playing cards, walking her beagle-mix rescue dog, Nellie, or cheering on the Chicago Cubs.
              </p>
              <p><a href="mailto:Erika.Pflederer@productivitynet.com?subject=Enquire from producticitynet.com">Erika.Pflederer@productivitynet.com</a></p>
            </div>

            <div className="founder-child-small">&lt;&gt;</div>
            <div className="founder-child mike no-bottom-margin">
              <img src={Mike} width="300px" alt="Mike Todd Wade - Founder" title="Mike Todd Wade - Founder" />
              <h2>MICHAEL TODD WADE</h2>
            </div>
          </div>
        </div>
        <div id="founder-container-wide" className="bottom-margin only-desktop">
          <p className="founder-bio">Erika grew up in central Illinois working at her Dad’s print shop before ultimately heading to Chicago to work in advertising. She has years of experience in nearly every aspect of production, from print to digital and motion. As an executive manager, she modernized and integrated production and studio teams of all sizes, turned production specialists into skilled, multi-faceted generalists, and grew studio revenue into the multiple millions. When she’s not reading the latest issue of <i>Fast Company</i>, you will likely find Erika playing cards, walking her beagle-mix rescue dog, Nellie, or cheering on the Chicago Cubs.
          </p>
          <p><a href="mailto:Erika.Pflederer@productivitynet.com?subject=Enquire from producticitynet.com">Erika.Pflederer@productivitynet.com</a></p>
        </div>
        <div id="founder-container-wide" className="bottom-margin">
          <p className="founder-bio">After graduating with a degree in Graphic Design, Mike worked for years in the prepress shops of Chicago’s ad agencies successfully delivering national and international print campaigns. As the industry’s technologies expanded, so did Mike’s skillset into digital and other disciplines within the world of advertising. He’s managed teams both within a single office and across multiple locations and time zones, overseeing processes to foster collaboration across a wide range of studio employees. Mike’s an avid music lover and spends his spare time playing guitar and dabbling with electronica and instrumental soundscapes in his home studio.
          </p>
          <p><a href="mailto:Michael.Wade@productivitynet.com?subject=Enquire from producticitynet.com">Michael.Wade@productivitynet.com</a></p>
        </div>
      </div>
    );
  }
}

Founders.propTypes = {
  inViewport: PropTypes.bool.isRequired,
};

const MySection = handleViewport(Founders, { rootMargin: '-1.0px' });

export default MySection;
