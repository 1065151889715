import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { PropTypes } from 'prop-types';
import { Event } from '../Tracking/Tracking';
import './PrintsNotOver.scss';

import NotDead from '../../Images/Home/prints_not_over.png';

import Footer from '../Footer/Footer';

class PrintsNotOver extends Component {
  constructor(props) {
    super(props);
    const { colorbg, colorcopy } = this.props;
    this.state = { color1: colorbg, color3: colorcopy };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('print-over');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 100);
  }

  trackIdeas = () => {
    const { inViewport } = this.props;
    if (inViewport) {
      Event('PRINTS NOT OVER ACTION', 'PRINTS NOT OVER ACTION - enter view', String(window.location.pathname));
    } if (!inViewport) {
      Event('PRINTS NOT OVER ACTION', 'PRINTS NOT OVER ACTION - leave view', String(window.location.pathname));
    }
    return {};
  }

  render() {
    const { color1, color3 } = this.state;
    return (
      <div style={this.trackIdeas()}>
        <div id="print-over" className="fadeOut" style={{ backgroundColor: color1 }}>
          <img src={NotDead} alt="image goes here" title="image goes here" />
          <div id="not-dead-copy">
            <p>Print is authentic. It’s tangible, tactile, and curated. Print is a reprieve from a pop-up, digital world that screams, “I’m tracking you!” <span className="only-desktopinline">Print hangs inspiration on your walls or wraps its words around a coffee mug you drink from every morning. Print is the packaging that keeps your ordered goods protected, and print is what you wear to cheer on your favorite sports team. Print is even the full-page newspaper ad athletes still use to say good-bye to a city they love.</span></p>
          </div>

          <Footer></Footer>

        </div>
      </div>
    );
  }
}

PrintsNotOver.propTypes = {
  colorbg: PropTypes.string.isRequired,
  colorlink: PropTypes.string.isRequired,
  colorcopy: PropTypes.string.isRequired,
  inViewport: PropTypes.bool.isRequired,
};


export default PrintsNotOver;
