/* eslint-disable max-len */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PPContext, thecontext } from '../../Context/index';
import { Event } from '../Tracking/Tracking';
import Player from '../Player/Player';
import './Navigation.scss';
import Social from '../Social/Social';

const pages = ['/', '/aboutus', '/aboutyou', '/services', '/client', '/contact'];
const pageColor = ['background-white', 'background-blue', 'background-orange', 'background-grey', 'background-white', 'background-white'];

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '#000064',
      iconColor: 'white',
      showMobile: true,
      showSocial: false,
    };
  }

  componentDidMount = () => {
    const setLoadColor = pages.indexOf(window.location.pathname);
    this.setPage(setLoadColor);
    this.updateDimensions();
    // window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount = () => {
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate = (newProps) => {
    const oldProps = this.props;
    if (oldProps.fillColor !== newProps.fillColor) {
      setTimeout(() => { this.update(newProps); }, 500);
    }
  }

  update = (newProps) => {
    this.setState({ iconColor: newProps.fillColor });
  }

  updateDimensions = () => {
    if (window.innerWidth <= 768) {
      this.setState({ showMobile: false });
    } else {
      this.setState({ showMobile: false });
    }
  }

  setPage = (pos) => {
    if (window.innerWidth <= 768) {
      // this.mobileNaviState();
    }

    this.mobileNaviState();

    window.scrollTo(0, 0);

    this.setSocial(pos);
    this.setNaviColor(pos);

    if (thecontext.historyPage === '') {
      this.setColors(pos);
    }

    this.fadeInContent();
    this.trackAnayltics();
  }

  fadeInContent = () => {
    const getContent = document.getElementById('content');
    getContent.style.opacity = 1;
  }

  setNaviColor = (pos) => {
    switch (pos) {
      case -1:
        this.setState({ color: '#000064', iconColor: 'white' });
        break;
      case 0:
        this.setState({ color: '#000064', iconColor: 'white' });
        break;
      case 1:
        this.setState({ color: '#000064', iconColor: 'white' });
        break;
      case 2:
        this.setState({ color: '#000064', iconColor: '#000064' });
        break;
      case 3:
        this.setState({ color: '#000064', iconColor: 'white' });
        break;
      case 4:
        this.setState({ color: '#000064', iconColor: '#000064' });
        break;
      case 5:
        this.setState({ color: '#000064', iconColor: '#000064' });
        break;
      default:
        this.setState({ color: '#000064', iconColor: '#000064' });
    }
  }

  setSocial = (pos) => {
    const getPlayer = document.getElementById('navi-player');
    if (pos === 0 || pos === 5) {
      this.setState({ showSocial: false });
      getPlayer.style.display = 'none';
    } else {
      this.setState({ showSocial: true });
      getPlayer.style.display = 'block';
    }
  }

  setColors = (pos) => {
    document.body.classList.remove(thecontext.bgcolor);
    thecontext.bgcolor = pageColor[pos];
    document.body.classList.add(pageColor[pos]);
  }

  mobileNaviState = () => {
    const { showMobile } = this.state;
    if (showMobile === false) {
      this.setState({ showMobile: true });
      this.mobileAnimation(true);
      Event('NAVIGATION_MOBILE', 'Hamburger menu open', String(window.location.pathname));
    } else {
      this.setState({ showMobile: false });
      this.mobileAnimation(false);
      Event('NAVIGATION_MOBILE', 'Hamburger menu closed', String(window.location.pathname));
    }
  }

  mobileAnimation = (showHide) => {
    const getNavi1 = document.getElementById('top-line-1');
    const getNavi2 = document.getElementById('middle-line-1');
    const getNavi3 = document.getElementById('bottom-line-1');
    const navigationContainer = document.getElementById('navigation');
    if (showHide) {
      navigationContainer.style.width = '100%';
      navigationContainer.style.height = '100%';

      getNavi1.classList.remove('closed-menu');
      getNavi2.classList.remove('closed-menu');
      getNavi3.classList.remove('closed-menu');
      getNavi1.classList.add('open-menu-1');
      getNavi2.classList.add('open-menu-2');
      getNavi3.classList.add('open-menu-3');
    } else {
      navigationContainer.style.width = '0%';
      navigationContainer.style.height = '0%';

      getNavi1.classList.add('closed-menu');
      getNavi2.classList.add('closed-menu');
      getNavi3.classList.add('closed-menu');
      getNavi1.classList.remove('open-menu-1');
      getNavi2.classList.remove('open-menu-2');
      getNavi3.classList.remove('open-menu-3');
    }
  }

  onKeyPressHandler = () => {

  }

  trackAnayltics = () => {
    Event('NAVIGATION', 'Go to page', String(window.location.pathname));
  }

  render() {
    const {
      iconColor, showSocial, showMobile, color,
    } = this.state;
    return (
      <div>
        <Social fillColor={iconColor} show={showSocial} />
        <div id="top-navigation">
          <div id="navi-player">
            <PPContext.Provider value={this.state}>
              <Player fillColor={iconColor} show={showSocial} />
            </PPContext.Provider>
          </div>
          <div id="hamburger" onClick={() => this.mobileNaviState()} onKeyPress={this.onKeyPressHandler} role="button" tabIndex="0">
            <svg id="i1" className="icon" viewBox="0 0 100 100">
              <path id="top-line-1" className="closed-menu" d="M30,37 L70,37 Z" />
              <path id="middle-line-1" className="closed-menu" d="M30,50 L70,50 Z" />
              <path id="bottom-line-1" className="closed-menu" d="M30,63 L70,63 Z" />
            </svg>
          </div>
        </div>
        <div id="navigation" className="background-white" style={{ visibility: showMobile ? 'visible' : 'hidden', opacity: showMobile ? '1' : '0' }}>
          <nav>
            <ul>
              <li>
                <Link style={{ color }} className="navi_link" to="/" onClick={() => this.setPage(0)}>Home</Link>
              </li>
              <li className="navi_link ticks" style={{ color }}>&lt;&gt;</li>
              <li>
                <Link style={{ color }} className="navi_link" to="/aboutus" onClick={() => this.setPage(1)}>About Us</Link>
              </li>
              <li className="navi_link ticks" style={{ color }}>&lt;&gt;</li>
              <li>
                <Link style={{ color }} className="navi_link" to="/work" onClick={() => this.setPage(2)}>Work</Link>
              </li>
              <li className="navi_link ticks" style={{ color }}>&lt;&gt;</li>
              <li>
                <Link style={{ color }} className="navi_link" to="/contact" onClick={() => this.setPage(5)}>Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Navigation;
