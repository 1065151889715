import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { thecontext } from '../../Context/index';
import { setSounds } from '../../Helpers';
import Contactaction from '../Contactaction/Contactaction';
import Worksamples from './Worksamples/Worksamples';
import Capabilities from '../Services/Capabilities/Capabilities';
import './Work.scss';
import '../Client/Client.scss';

import { ReactComponent as Allstate } from '../../Images/SVG/Allstate-logo.svg';
import { ReactComponent as Bluecross } from '../../Images/SVG/Bluecross-logo.svg';
import { ReactComponent as Coke } from '../../Images/SVG/Coke-logo.svg';
import { ReactComponent as Disney } from '../../Images/SVG/Disney-logo.svg';
import { ReactComponent as Gatorade } from '../../Images/SVG/Gatorade-logo.svg';

import { ReactComponent as Harris } from '../../Images/SVG/Harris-logo.svg';
import { ReactComponent as Hershey } from '../../Images/SVG/Hershey-logo.svg';
import { ReactComponent as Kellogs } from '../../Images/SVG/Kellogs-logo.svg';
import { ReactComponent as Marriot } from '../../Images/SVG/Marriot-logo.svg';
import { ReactComponent as Maytag } from '../../Images/SVG/Maytag-logo.svg';

import { ReactComponent as Oscar } from '../../Images/SVG/Oscar-logo.svg';
import { ReactComponent as Pilsbury } from '../../Images/SVG/Pilsbury-logo.svg';
import { ReactComponent as Quaker } from '../../Images/SVG/Quaker-logo.svg';
import { ReactComponent as Rust } from '../../Images/SVG/Rust-logo.svg';
import { ReactComponent as Sharpie } from '../../Images/SVG/Sharpie-logo.svg';

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('work');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 10);
    setSounds('aboutyou', thecontext);
  }

  render = () => (
    <div>
      <div id="work" className="centerVH fadeOut">
        <Helmet>
          <meta charSet="utf-8" />
          <title>We Are Productivity Network / Work</title>
          <meta name="description" content={thecontext.metaDescription} />
        </Helmet>

        <Worksamples />
        <div>
          <Capabilities colorbg="#000064" colorcopy="white"></Capabilities>
        </div>

        <div id="client-container">
          <h1>CLIENT EXPERIENCE</h1>
          <div id="clients">
            <div className="client-logo">
              <Oscar className="logo-icon" />
            </div>
            <div className="client-logo">
              <Pilsbury className="logo-icon" />
            </div>
            <div className="client-logo">
              <Quaker className="logo-icon" />
            </div>
            <div className="client-logo">
              <Rust className="logo-icon" />
            </div>
            <div className="client-logo">
              <Sharpie className="logo-icon" />
            </div>

            <div className="client-logo">
              <Harris className="logo-icon" />
            </div>
            <div className="client-logo">
              <Hershey className="logo-icon" />
            </div>
            <div className="client-logo">
              <Kellogs className="logo-icon" />
            </div>
            <div className="client-logo">
              <Marriot className="logo-icon" />
            </div>
            <div className="client-logo">
              <Maytag className="logo-icon" />
            </div>

            <div className="client-logo">
              <Allstate className="logo-icon" />
            </div>
            <div className="client-logo">
              <Bluecross className="logo-icon" />
            </div>
            <div className="client-logo">
              <Coke className="logo-icon" />
            </div>
            <div className="client-logo">
              <Disney className="logo-icon" />
            </div>
            <div className="client-logo">
              <Gatorade className="logo-icon" />
            </div>
          </div>
          </div>
        </div>

      <div>
        <Contactaction colorbg="#000064" colorcopy="#ffffff" colorlink="#f7941d" />
      </div>
    </div>
  )
}

export default Work;
