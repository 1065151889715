import React, { Component } from 'react';
import './Logo.scss';

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
  }

  componentDidMount = () => {
    this.setState({ isVisible: true });
  }

  render() {
    const { isVisible } = this.state;
    return (
      <div id="logo" style={{ display: isVisible ? 'block' : 'none' }}>
        <h1 className="sub-1">PRODUCTIVITY</h1>
        <h2 className="sub-2">NETWORK</h2>
      </div>
    );
  }
}

export default Logo;
