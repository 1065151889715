/* eslint-disable max-len */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { thecontext } from '../../Context/index';
import { setSounds } from '../../Helpers';
import Founders from './Founders/Founders';
import Contactaction from '../Contactaction/Contactaction';
import './Aboutus.scss';

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('about-us');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 10);
    setSounds('aboutus', thecontext);
  }

  render = () => (
    <div>
      <div id="about-us" className="centerVH fadeOut">
        <Helmet>
          <meta charSet="utf-8" />
          <title>We Are Productivity Network / About Us</title>
          <meta name="description" content={thecontext.metaDescription} />
        </Helmet>
        {/* <div id="top-fade" /> */}
        <h1 className="header">About Us</h1>
        <p className="no-top">You’ve seen our work.</p>
        <p>We are a network of A-list advertising production professionals who have worked at every major advertising agency on every major brand category you can likely name.<br/>We’re bringing all of that expertise and experience directly to you. We provide on-demand production resources freeing you up to do what you do best, your day job.
        </p>
        <p className="subhead">WHETHER YOU’RE A CREATIVE AGENCY THAT WANTS TO OFFER PRODUCTION, AN ESTABLISHED BRAND IN SEARCH OF A NEW, BETTER WAY, OR A START-UP BUSINESS DOWN THE VIRTUAL STREET, WE CAN HELP MAKE THE ADVERTISING MATERIALS YOU NEED.
        </p>
        <p>We’re here to support you in your business endeavors, and to turn your ideas into tangible, polished, final pieces of consumable art. We’re good at this. We’re resourceful, experienced, and efficient, so we welcome your questions, your hopes, and especially your “Can that even be done?”</p>
        <p>We believe it can.</p>
      </div>
      <Founders enterCount={0} leaveCount={0} />
      <Contactaction colorbg="#000064" colorcopy="white" colorlink="#f7941d" />
    </div>
  );
}

export default Aboutus;
