import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { PropTypes } from 'prop-types';
import { Event } from '../Tracking/Tracking';
import './Contactaction.scss';

class Contactaction extends Component {
  constructor(props) {
    super(props);
    const { colorbg, colorlink, colorcopy } = this.props;
    this.state = { color1: colorbg, color2: colorlink, color3: colorcopy };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('contact-action');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 100);
  }

  trackContactAction = () => {
    const { inViewport } = this.props;
    if (inViewport) {
      Event('CONTACT ACTION', 'CONTACT ACTION - enter view', String(window.location.pathname));
    } if (!inViewport) {
      Event('CONTACT ACTION', 'CONTACT ACTION - leave view', String(window.location.pathname));
    }
    return {};
  }

  render() {
    const { color1, color2, color3 } = this.state;
    return (
      <div style={this.trackContactAction()}>
        <div id="contact-action" className="fadeOut" style={{ backgroundColor: color1 }}>
          <h1 style={{ color: color3 }}>
            Le
            <span className="apos">t&apos;</span>
            s Connect
          </h1>
          <p className="small" style={{ color: color3 }}>
            You bring the Ideas. We&apos;ll&nbsp;bring&nbsp;the&nbsp;Productivity.
            <span className="pBreak">Let&apos;s work differently together.</span>
          </p>
          <p className="bold" style={{ color: color3 }}>
            Reach us directly @:&nbsp;
            <a style={{ color: color2 }} href="mailto:info@productivitynet.com?subject=Enquire from producticitynet.com">info@productivitynet.com</a>
          </p>
        </div>
      </div>
    );
  }
}

Contactaction.propTypes = {
  colorbg: PropTypes.string.isRequired,
  colorlink: PropTypes.string.isRequired,
  colorcopy: PropTypes.string.isRequired,
  inViewport: PropTypes.bool.isRequired,
};

const ContactSection = handleViewport(Contactaction, { rootMargin: '-1.0px' });

export default ContactSection;
