import React from 'react';

export const thecontext = {
  count: 0,
  pages: ['aboutus', 'services', 'clients', 'aboutyou'],
  show: [true, true, true, true],
  showplayer: true,
  bgcolor: 'background-white',
  metaDescription: 'Print’s Not Over! If you want it…we are advertising production experts that specialize in the producing of print materials, photoshop retouching, online ads, social media assets, and HTML5 development.',
  historyPage: '',
};

export const PPContext = React.createContext(
  thecontext,
);
