/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import './Social.scss';
import { ReactComponent as Fbicon } from '../../Images/SVG/Fb-icon.svg';
import { ReactComponent as Insta } from '../../Images/SVG/Insta-icon.svg';

class Social extends Component {
  constructor(props) {
    super(props);
    this.state = { IconsColor: 'white', isVisible: false };
  }

  componentDidMount = () => {
    const { fillColor } = this.props;
    this.setState({ IconsColor: fillColor });
  }

  componentDidUpdate(newProps) {
    const oldProps = this.props;
    if (oldProps !== newProps) {
      setTimeout(() => { this.update(newProps); }, 500);
    }
  }

  update = (newProps) => {
    const { fillColor } = this.props;
    const oldProps = this.props;
    if (oldProps !== newProps ) {
      this.setState({ IconsColor: fillColor });
    }
  }

  render() {
    const { isVisible, IconsColor } = this.state;
    return (
      <div id="social" style={{ display: isVisible ? 'block' : 'none' }}>
        <a href="https://www.facebook.com"><Fbicon className="social-icon" fill={IconsColor} /></a>
        <a href="https://www.instagram.com"><Insta className="social-icon" fill={IconsColor} /></a>
      </div>
    );
  }
}

Social.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default Social;
