import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { PropTypes } from 'prop-types';
import { Event } from '../../Tracking/Tracking';
import './Ideas.scss';

class Ideas extends Component {
  constructor(props) {
    super(props);
    const { colorbg, colorcopy } = this.props;
    this.state = { color1: colorbg, color3: colorcopy };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('ideas');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 100);
  }

  trackIdeas = () => {
    const { inViewport } = this.props;
    if (inViewport) {
      Event('CONTACT ACTION', 'CONTACT ACTION - enter view', String(window.location.pathname));
    } if (!inViewport) {
      Event('CONTACT ACTION', 'CONTACT ACTION - leave view', String(window.location.pathname));
    }
    return {};
  }

  render() {
    const { color1, color3 } = this.state;
    return (
      <div style={this.trackIdeas()}>
        <div id="ideas" className="fadeOut" style={{ backgroundColor: color1 }}>
          <h1 style={{ color: color3 }}>
            <span className="ideas-break">You bring the&nbsp;Ideas.</span>
            We’ll bring the&nbsp;Productivity.
          </h1>
          <div id="idea-list" style={{ color: color3 }}>
            <div id="idea-list-inner">
              <ul>
                <li>
                  <span>•</span>
                  An expert production team ready to lead you to the creative finish&nbsp;line.
                </li>
                <li>
                  <span>•</span>
                  The moxie to keep print and retouching totally within our&nbsp;wheelhouse.
                </li>
                <li>
                  <span>•</span>
                  And yes, of course we do digital. Print’s not over, but we’re definitely plugged&nbsp;in.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Ideas.propTypes = {
  colorbg: PropTypes.string.isRequired,
  colorlink: PropTypes.string.isRequired,
  colorcopy: PropTypes.string.isRequired,
  inViewport: PropTypes.bool.isRequired,
};

const ContactSection = handleViewport(Ideas, { rootMargin: '-1.0px' });

export default ContactSection;
