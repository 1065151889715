import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import PlayerFooter from '../PlayerFooter/PlayerFooter';
import './Footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    const { colorbg, pause } = this.props;
    this.state = {
      color1: colorbg,
      iconColor: 'white',
      showSocial: pause,
    };

    // setInterval(() => { this.update(); }, 500);
  }

  // update = () => {
  //   const { count } = this.state;
  //     this.setState({
  //       getPath: window.location.pathname,
  //   }
  // }

  render() {
    const {
      color1, iconColor, showSocial,
    } = this.state;
    return (
      <div>
        <div id="Footer" style={{ backgroundColor: color1 }} />
        <div className="footer-player">
          <PlayerFooter fillColor={iconColor} show={showSocial} />
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  colorbg: PropTypes.string.isRequired,
};

export default Footer;
