import React from 'react';
import { withRouter } from 'react-router';
import { PropTypes } from 'prop-types';
import { thecontext } from '../../Context/index';

// variable which will point to react-router history
let globalHistory = null;

const pageColor = ['background-white', 'background-blue', 'background-orange', 'background-grey', 'background-white', 'background-white'];
const pages = ['/', '/aboutus', '/aboutyou', '/services', '/client', '/contact'];

// component which we will mount on top of the app
class Spy extends React.Component {
  constructor(props) {
    super(props);
    globalHistory = props.history;
  }

  componentDidUpdate = () => {
    const { history } = this.props;
    globalHistory = history;
    thecontext.historyPage = globalHistory.location.pathname;
    const getPage = pages.indexOf(String(thecontext.historyPage));
    this.setColors(getPage);
    this.setPlayer(getPage);
  }

  setColors = (pos) => {
    document.body.classList.remove(thecontext.bgcolor);
    thecontext.bgcolor = pageColor[pos];
    document.body.classList.add(pageColor[pos]);
  }

  setPlayer = (pos) => {
    if (pos === 0) {
      thecontext.showplayer = false;
    } else {
      thecontext.showplayer = true;
    }
  }

  render = () => null
}

Spy.propTypes = {
  history: PropTypes.object.isRequired,
};

export const GlobalHistory = withRouter(Spy);

// export react-router history
export function getHistory() {
  return globalHistory;
}
