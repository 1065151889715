export function removeArray(arr) {
  let what;
  const a = arguments;
  let L = a.length;
  let ax;
  while (L > 1 && arr.length) {
    what = a[L -= 1];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

export function setSounds(thepage, myContext) {
  const setContext = myContext;
  const checkPages = myContext.pages.indexOf(thepage);
  if (myContext.show[checkPages] === false) {
    setContext.show[checkPages] = true;
    setContext.count += 1;
  }
}
