import React, { Component } from 'react';
import Whirligig from 'react-whirligig';
import { Event } from '../../Tracking/Tracking';
import './Worksamples.scss';

import work1 from '../../../Images/Work/image1.jpg';
import work2 from '../../../Images/Work/image2.jpg';
import work3 from '../../../Images/Work/image3.jpg';
import work4 from '../../../Images/Work/image4.jpg';
import work5 from '../../../Images/Work/image5.jpg';
import work6 from '../../../Images/Work/image6.jpg';
import work7 from '../../../Images/Work/image7.jpg';
import work8 from '../../../Images/Work/image8.jpg';
import work9 from '../../../Images/Work/image9.jpg';
import work10 from '../../../Images/Work/image10.jpg';
import work11 from '../../../Images/Work/image11.jpg';
import work12 from '../../../Images/Work/image12.jpg';
import work13 from '../../../Images/Work/image13.jpg';
import work14 from '../../../Images/Work/image14.jpg';
import work15 from '../../../Images/Work/image15.jpg';
import work16 from '../../../Images/Work/image16.jpg';
import work17 from '../../../Images/Work/image17.jpg';
import work18 from '../../../Images/Work/image18.jpg';
import work19 from '../../../Images/Work/image19.jpg';
import work20 from '../../../Images/Work/image20.jpg';
import work21 from '../../../Images/Work/image21.jpg';
import work22 from '../../../Images/Work/image22.jpg';


import banner1 from '../../../Video/AM_Banner_Work.mp4';
import banner1webm from '../../../Video/PN_Vid_Final.webm';
import banner1poster from '../../../Images/hero_poster.jpg';

import banner2 from '../../../Video/Brand_Banner_Work.mp4';
import banner2webm from '../../../Video/PN_Vid_Final.webm';
import banner2poster from '../../../Images/hero_poster.jpg';

class Worksamples extends Component {
  constructor(props) {
    super(props);
    this.state = { visibleSlides: 2 };
  }

  componentDidMount = () => {
    this.updateSlider();
    window.addEventListener('resize', this.updateSlider.bind(this));
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateSlider.bind(this));
  }

  updateSlider = () => {
    if (window.innerWidth <= 560) {
      this.setState({ visibleSlides: 1 });
    } else if (window.innerWidth <= 768) {
      this.setState({ visibleSlides: 2 });
    } else if (window.innerWidth <= 1024) {
      this.setState({ visibleSlides: 2 });
    } else if (window.innerWidth <= 1280) {
      this.setState({ visibleSlides: 2 });
    }
  }

  beforeSlide = (e) => {
    const setId = e.target.id;
    if (setId === 'button-prev') {
      Event('WORK SAMPLES', 'SLIDE CLICK - PREV', String(window.location.pathname));
    } else {
      Event('WORK SAMPLES', 'SLIDE CLICK - NEXT', String(window.location.pathname));
    }
  }

  render() {
    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();
    const {
      visibleSlides,
    } = this.state;
    return (
    // <div id="Worksamples" style={{ display: isVisible ? 'block' : 'none' }} />

      <div id="work-samples" className="">
        <h1>WORK</h1>
        <div id="work-container">
          <Whirligig
            visibleSlides={visibleSlides}
            gutter="0px"
            infinite="true"
            ref={(_whirligigInstance) => { whirligig = _whirligigInstance; }}
          >
            <img className="slider-img" src={work11} width="400px" alt="" />
            <img className="slider-img" src={work12} width="400px" alt="" />
            <img className="slider-img" src={work13} width="400px" alt="" />
            <img className="slider-img" src={work14} width="400px" alt="" />
            <img className="slider-img" src={work15} width="400px" alt="" />
            <img className="slider-img" src={work16} width="400px" alt="" />


            <img className="slider-img" src={work17} width="400px" alt="" />
            <img className="slider-img" src={work18} width="400px" alt="" />
            <img className="slider-img" src={work19} width="400px" alt="" />
            <img className="slider-img" src={work20} width="400px" alt="" />

            <div className="slider-vid">
              <video id="myVideo1" width="300px" height="250px" loop muted autoPlay playsInline poster={banner1poster}>
                <source src={banner1} type="video/mp4" />
                <source src={banner1webm} type="video/webm" />
              </video>
            </div>
            <div className="slider-vid">
              <video id="myVideo2" width="300px" height="250px" loop muted autoPlay playsInline poster={banner2poster}>
                <source src={banner2} type="video/mp4" />
                <source src={banner2webm} type="video/webm" />
              </video>
            </div>

            <img className="slider-img" src={work21} width="400px" alt="" />
            <img className="slider-img" src={work22} width="400px" alt="" />

            <img className="slider-img" src={work3} width="400px" alt="" />
            <img className="slider-img" src={work4} width="400px" alt="" />
            <img className="slider-img" src={work5} width="400px" alt="" />
            <img className="slider-img" src={work6} width="400px" alt="" />
            <img className="slider-img" src={work7} width="400px" alt="" />
            <img className="slider-img" src={work8} width="400px" alt="" />
            <img className="slider-img" src={work9} width="400px" alt="" />
            <img className="slider-img" src={work10} width="400px" alt="" />

            <img className="slider-img" src={work1} width="400px" alt="" />
            <img className="slider-img" src={work2} width="400px" alt="" />

          </Whirligig>
        </div>
        <button id="button-prev" type="button" onClick={(e) => { prev(); this.beforeSlide(e); }}>&lt;</button>
        <button id="button-next" type="button" onClick={(e) => { next(); this.beforeSlide(e); }}>&gt;</button>
      </div>
    );
  }
}

export default Worksamples;
