import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Logo from '../Logo/Logo';
import PlayerOnly from '../PlayerOnly/PlayerOnly';
import './Contact.scss';
import { thecontext } from '../../Context';

const API_PATH = 'https://www.productivitynet.com/Send/contact/index.php';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      mailSent: false,
      error: null,
    };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('contact');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 10);
  }

  onNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          name: '',
          email: '',
          message: '',
          mailSent: result.data.sent,
        });
      })
      .catch((error) => this.setState({ error: error.message }));
  }

  render() {
    const {
      name, email, message, mailSent,
    } = this.state;
    return (
      <div id="contact" className="centerVH fadeOut">
        <Helmet>
          <meta charSet="utf-8" />
          <title>We Are Productivity Network / Contact</title>
          <meta name="description" content={thecontext.metaDescription} />
        </Helmet>
        {/* <div id="top-fade" /> */}
        <h1>Contact</h1>
        <Logo />
        <p>
          Reach us directly @:
          <a href="mailto:info@productivitynet.com?subject=Enquire from producticitynet.com"> info@productivitynet.com</a>
        </p>
        <p>or</p>
        <form method="post" id="contact-form" name="contact_form" onSubmit={this.handleSubmit.bind(this)}>
          <input type="text" className="form-control" name="name" id="name" value={name} placeholder="YOUR NAME" onChange={this.onNameChange.bind(this)} required />
          <input type="email" className="form-control" name="email" id="email" value={email} aria-describedby="emailHelp" placeholder="YOUR EMAIL" onChange={this.onEmailChange.bind(this)} required />
          <textarea name="message" className="form-control" id="message" value={message} placeholder="YOUR MESSAGE" onChange={this.onMessageChange.bind(this)} required />
          <input type="submit" value="SUBMIT" />
        </form>
        <div>
          {mailSent
            && <div>Thank you, We will be in contact as soon as possible.</div>}
        </div>
        <PlayerOnly />
      </div>
    );
  }
}

export default Contact;
