import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { PropTypes } from 'prop-types';
import { Event } from '../../Tracking/Tracking';
import './Capabilities.scss';

class Capabilities extends Component {
  constructor(props) {
    super(props);
    const { colorbg, colorcopy } = this.props;
    this.state = { color1: colorbg, color3: colorcopy };
  }

  componentDidMount = () => {
    const getContainer = document.getElementById('capabilities-action');
    setTimeout(() => getContainer.classList.remove('fadeOut'), 100);
  }

  trackCapabilitiesAction = () => {
    const { inViewport } = this.props;
    if (inViewport) {
      Event('CAPABILITIES ACTION', 'CAPABILITIES ACTION - enter view', String(window.location.pathname));
    } if (!inViewport) {
      Event('CAPABILITIES ACTION', 'CAPABILITIES ACTION - leave view', String(window.location.pathname));
    }
    return {};
  }

  render() {
    const { color1, color3 } = this.state;
    return (
      <div style={this.trackCapabilitiesAction()}>
        <div id="capabilities-action" className="fadeOut">

          <div id="services-included" style={{ backgroundColor: color1 }}>
            <h1 style={{ color: color3 }}>
              <b>
                PRODUCTIVITY NETWORK SERVICES
              </b>
            </h1>
            <div id="services-child">
              <p style={{ color: color3 }}>
                Creative Retouching
                <span className="bullet"> • </span>
                Digital Design/Templates
                <span className="bullet"> • </span>
                HTML5 Development 
                <span className="bullet"> • </span>
                Print Layout/Design
                <span className="bullet"> • </span>
                Display
                <span className="bullet"> • </span>
                Branding Materials
                <span className="bullet"> • </span>
                Collateral
                <span className="bullet"> • </span>
                Billboards
                <span className="bullet"> • </span>
                Bus Shelters
                <span className="bullet"> • </span>
                Print Ads
                <span className="bullet"> • </span>
                Letterpress
                <span className="bullet"> • </span>
                Screen Printing
                <span className="bullet"> • </span>
                Offset Printing

              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Capabilities.propTypes = {
  colorbg: PropTypes.string.isRequired,
  colorlink: PropTypes.string.isRequired,
  colorcopy: PropTypes.string.isRequired,
  inViewport: PropTypes.bool.isRequired,
};

const CapabilitiesSection = handleViewport(Capabilities, { rootMargin: '-1.0px' });

export default CapabilitiesSection;
