import React, { Component } from 'react';
import {
  Route, BrowserRouter, Switch,
} from 'react-router-dom';
import { GlobalHistory } from './Components/History/History';
import Navigation from './Components/Navigation/Navigation';
import Home from './Components/Home/Home';
import Aboutus from './Components/Aboutus/Aboutus';
// import Aboutyou from './Components/Aboutyou/Aboutyou';
// import Services from './Components/Services/Services';
// import Client from './Components/Client/Client';
import Work from './Components/Work/Work';
import Contact from './Components/Contact/Contact';
import Fourzerofour from './Components/Fourzerofour/Fourzerofour';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidUpdate() {
    this.setStat({ location: window.location.pathname });
  }

  render() {
    return (
      <div id="prod-app" className="App">
        <BrowserRouter basename="/">
          <GlobalHistory />
          <Navigation />
          <div id="content">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/aboutus" exact component={Aboutus} />
              {/* <Route path="/aboutyou" exact component={Aboutyou} />
              <Route path="/services" exact component={Services} />
              <Route path="/client" exact component={Client} /> */}
               <Route path="/work" exact component={Work} />
              <Route path="/contact" exact component={Contact} />
              <Route path="*" component={Fourzerofour} />
            </Switch>
          </div>
          {/* <Footer bgcolor="white" /> */}
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
