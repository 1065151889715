import React, { Component } from 'react';
import { Howl } from 'howler';
import { Event } from '../Tracking/Tracking';
import { PPContext, thecontext } from '../../Context/index';
import { removeArray } from '../../Helpers';
import './Player.scss';

import blueAudio from '../../Sounds/Kick_Blue.mp3';
import grey1Audio from '../../Sounds/Snare_Gray.mp3';
import orangeAudio from '../../Sounds/Kick_Orange.mp3';
import grey2Audio from '../../Sounds/Tom_Gray.mp3';

import { ReactComponent as Pptext } from '../../Images/SVG/PP_logo_text.svg';

const saveSounds = [];
const saveDivs = [];
const saveVolume = [];

const sound1 = new Howl({
  src: [blueAudio],
  loop: true,
  volume: 1,
  autoplay: false,
});
const sound2 = new Howl({
  src: [grey1Audio],
  loop: true,
  volume: 1,
  autoplay: false,
});
const sound3 = new Howl({
  src: [orangeAudio],
  loop: true,
  volume: 1,
  autoplay: false,
});
const sound4 = new Howl({
  src: [grey2Audio],
  loop: true,
  volume: 1,
  autoplay: false,
});

const renderButtons = [
  [sound1, 'button-1', 1, 1],
  [sound2, 'button-2', 1, 2],
  [sound3, 'button-3', 1, 3],
  [sound4, 'button-4', 1, 4],
];

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: thecontext.show,
    };
  }

  componentDidMount = () => {
    // this.hidePrompt();
  }

  componentDidUpdate(newProps, oldProps) {
    if (newProps !== oldProps) {
      setTimeout(() => { this.update(newProps); }, 100);
      this.soundsPause();
    }
  }

  componentWillUnmount = () => {
    this.soundsPause();
  }

  update = (newProps) => {
    const oldProps = this.props;
    if (oldProps !== newProps) {
      this.setState({

      });

      if (!thecontext.showplayer) {
        this.soundsPauseHome();
        this.removeBlink();
      }
    }
  }

  soundManager = (theSound) => {
    const setSound = theSound[0];
    const s = saveSounds.includes(theSound[0]);

    if (s === false) {
      saveSounds.push(setSound);
      saveDivs.push(theSound[1]);
      this.addBlink();
      this.soundsPlay(theSound[2]);
      Event('MUSIC', 'SOUND CLICKED - start', String(theSound[1]));
    } else {
      this.soundsPause();
      this.removeBlink();
      removeArray(saveSounds, setSound);
      removeArray(saveDivs, theSound[1]);
      this.addBlink();
      this.soundsPlay(theSound[2]);
      Event('MUSIC', 'SOUND CLICKED - stop', String(theSound[1]));
    }
  }

  soundsPauseHome = () => {
    saveSounds.map((item, i) => {
      saveSounds[i].volume(0);
      saveSounds[i].seek(0);
      saveSounds[i].pause();
      return null;
    });
    this.removeBlink();
    saveSounds.length = 0;
    saveDivs.length = 0;
    saveVolume.length = 0;
  }

  soundsPause = () => {
    saveSounds.map((item, i) => {
      saveSounds[i].volume(0);
      saveSounds[i].seek(0);
      saveSounds[i].pause();
      return null;
    });
    this.removeBlink();
  }

  soundsPlay = () => {
    saveSounds.map((item, i) => {
      saveSounds[i].volume(1);
      saveSounds[i].pause();
      saveSounds[i].seek(0);
      saveSounds[i].play();
      return null;
    });
  }

  add1 = (thenumber) => {
    const goforItBro = thenumber + 1;
    return goforItBro;
  }

  addBlink = () => {
    saveDivs.map((item, i) => {
      document.getElementById(saveDivs[i]).classList.remove('blink');
      document.getElementById(saveDivs[i]).classList.add('blink');
      return null;
    });
  }

  removeBlink = () => {
    saveDivs.map((item, i) => {
      document.getElementById(saveDivs[i]).classList.remove('blink');
      return null;
    });
  }

  handleKeyPress = () => {

  }

  sliderVolume = (current, input) => {
    renderButtons[current][2] = input;
    saveSounds[current].volume(renderButtons[current][2]);
  }

  render = () => {
    const { show } = this.state;
    return (
      <div>
        <div id="player_container">
          {/* style={{ display: isVisible ? 'block' : 'none' }} */}
          <div id="prompt">
            <Pptext className="pp-svg" />
          </div>
          <div id="player">
            {
                renderButtons.length === 0
                  ? ''
                  : renderButtons.map((data, index) => (
                    <PPContext.Consumer key={renderButtons[index][3]}>
                      {({ value }) => (
                        <div className={`player-button ${show[index] ? '' : 'hidden'}`}>
                          <div id={`button-${this.add1(index)}`} onKeyPress={this.handleKeyPress} onClick={() => this.soundManager(renderButtons[index])} role="button" tabIndex="0">
                            <div className="button-active" />
                            <div className="button-color" />
                          </div>
                        </div>
                      )}
                    </PPContext.Consumer>
                  ))
                }
          </div>
        </div>
      </div>
    );
  }
}

Player.propTypes = {

};

Player.contextType = PPContext;

export default Player;
